import { useState, useEffect } from 'react'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import { messages } from '../../utils/messages/index.js'
import refDataByKey from '../../services/localRefData/refDataByKey.js'

const checkedIcon = <CheckBoxIcon fontSize="small" />
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

const AutocompleteFilter = ({ filter, readOnly, onChange }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const getFilterOptions = async () => {
      let newOptions = []
      if (filter.refDataKey) {
        newOptions = (await refDataByKey(filter.refDataKey).catch(e => console.error(e))).map(
          refData => {
            return {
              id: refData.id,
              name: filter.name,
              label: refData[messages.getLangKey()],
            }
          },
        )
      }
      setOptions(newOptions)
    }

    getFilterOptions()
  }, [filter])

  return (
    options && (
      <Autocomplete
        disableCloseOnSelect
        multiple
        disabled={readOnly}
        getOptionLabel={option => option.label}
        id={filter.name}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        limitTags={3}
        options={options}
        renderInput={params => (
          <TextField {...params} label={filter.label} placeholder={filter.placeholder} />
        )}
        renderOption={(props, option, { selected }) => (
          <li key={`${filter.name}-af-${option.id}`} {...props}>
            <Checkbox
              checked={selected}
              checkedIcon={checkedIcon}
              icon={icon}
              size="small"
              style={{ marginRight: 8 }}
            />
            {option.label}
          </li>
        )}
        onChange={(event, value) => {
          onChange(value)
        }}
      />
    )
  )
}

export default AutocompleteFilter
