const stgNavbarTheme = {
  palette: {
    // Maneja el color de la tab selected y su base
    primary: {
      main: '#FFFFFF',
    },
    selectedRole: {
      main: '#1976d2',
    },
    background: {
      default: '#D9785F',
    },
  },
  logo: {
    default: '../../logo_blanco.png',
    isTraining: false,
  },
}

export default stgNavbarTheme
